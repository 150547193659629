var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"position":"relative","z-index":"80"}},[_c('Pane',{attrs:{"bottom":0,"titleBottom":0}},[_c('a-tabs',{attrs:{"tabBarStyle":{
          margin: '0',
          borderBottom: 'none',
        }},model:{value:(_vm.selectedKey),callback:function ($$v) {_vm.selectedKey=$$v},expression:"selectedKey"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"基本信息"}}),_c('a-tab-pane',{key:"2",attrs:{"tab":"功能权限"}})],1)],1)],1),_c('div',{staticStyle:{"margin-top":"-32.8px","position":"relative","z-index":"1"}},[_c('a-tabs',{model:{value:(_vm.selectedKey),callback:function ($$v) {_vm.selectedKey=$$v},expression:"selectedKey"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":""}},[_c('div',{staticClass:"container info"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'code',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入！',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'code',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请输入！',\n                          },\n                        ],\n                      },\n                    ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'name',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请输入！',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'name',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: '请输入！',\n                          },\n                        ],\n                      },\n                    ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"描述","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remarks']),expression:"['remarks']"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v("下一步")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)]),_c('a-tab-pane',{key:"2",attrs:{"tab":""}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"container"},[_c('a-checkbox-group',{model:{value:(_vm.selectedList),callback:function ($$v) {_vm.selectedList=$$v},expression:"selectedList"}},_vm._l((_vm.renderList(_vm.permissionList)),function(element,index){return _c('div',{key:index},[_c('h3',{staticClass:"group-name"},[_vm._v(_vm._s(element.name))]),_c('div',{staticClass:"permission-list"},[_c('a-row',{attrs:{"gutter":[8, 8]}},_vm._l((element.list),function(permission){return _c('a-col',{key:permission.uuid,staticClass:"text-hidden",attrs:{"span":4}},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(permission.name)+" ")]),_c('a-checkbox',{attrs:{"value":permission.uuid}},[_vm._v(_vm._s(permission.name))])],2)],1)}),1)],1)])}),0),_c('div',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }