<template>
  <div>
    <div style="position: relative; z-index: 80">
      <Pane :bottom="0" :titleBottom="0">
        <a-tabs
          v-model="selectedKey"
          :tabBarStyle="{
            margin: '0',
            borderBottom: 'none',
          }"
        >
          <a-tab-pane key="1" tab="基本信息"> </a-tab-pane>
          <a-tab-pane key="2" tab="功能权限"> </a-tab-pane>
        </a-tabs>
      </Pane>
    </div>

    <div style="margin-top: -32.8px; position: relative; z-index: 1">
      <a-tabs v-model="selectedKey">
        <a-tab-pane key="1" tab="">
          <div class="container info">
            <a-form
              :form="form"
              @submit="handleSubmit"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
              :colon="false"
            >
              <a-row>
                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="编码">
                    <a-input
                      v-decorator="[
                        'code',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入！',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="名称">
                    <a-input
                      v-decorator="[
                        'name',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入！',
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>

                <a-col :lg="24" :md="24" :sm="24">
                  <a-form-item
                    label="描述"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-textarea
                      :auto-size="{ minRows: 3 }"
                      v-decorator="['remarks']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <div class="center">
                <a-space>
                  <a-button htmlType="submit" type="primary">下一步</a-button>
                  <a-button @click="$close($route.path)">关闭</a-button>
                </a-space>
              </div>
            </a-form>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="">
          <div class="container">
            <div class="container">
              <a-checkbox-group v-model="selectedList">
                <div
                  v-for="(element, index) in renderList(permissionList)"
                  :key="index"
                >
                  <h3 class="group-name">{{ element.name }}</h3>

                  <div class="permission-list">
                    <a-row :gutter="[8, 8]">
                      <a-col
                        :span="4"
                        class="text-hidden"
                        v-for="permission in element.list"
                        :key="permission.uuid"
                      >
                        <a-tooltip>
                          <template slot="title">
                            {{ permission.name }}
                          </template>
                          <a-checkbox :value="permission.uuid">{{
                            permission.name
                          }}</a-checkbox>
                        </a-tooltip>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </a-checkbox-group>

              <div>
                <a-space>
                  <a-button type="primary" @click="save">保存</a-button>
                  <a-button @click="$close($route.path)">关闭</a-button>
                </a-space>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { add } from "@/api/setting/role";

import { fetchList as fetchPermissionList } from "@/api/setting/permission";
export default {
  name: "addRole",

  data() {
    return {
      selectedKey: "1",

      form: this.$form.createForm(this),

      role: {},
      selectedList: [], // 已选中的权限
      permissionList: [], // 权限列表

      storageValue: [], // 暂存数据
    };
  },

  activated() {
    fetchPermissionList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.permissionList = res.list;
      }
    });
  },

  methods: {
    renderList(list) {
      const groupNames = list
        .map((item) => item.groupName)
        .filter((item, index, self) => self.indexOf(item) === index);
      return groupNames.map((name) => {
        return {
          name,
          list: list.filter((item) => item.groupName === name),
        };
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.storageValue = values;
          this.selectedKey = "2";

          // if (this.role.uuid) {
          //   // 处于修改状态
          //   const params = {
          //     ...this.role,
          //     ...values,
          //   };
          //   edit(params).then(() => {
          //     this.role = params;
          //   });
          // } else {
          //   add(values).then((res) => {
          //     if (res && res.data) {
          //       this.role = res.data;
          //     }
          //   });
          // }
        }
      });
    },

    save() {
      if (!this.storageValue.code || !this.storageValue.name) {
        this.$message.error("请先填写角色基本信息！");
        return;
      }

      if (this.selectedList.length === 0) {
        this.$message.error("请选择权限！");
        return;
      }

      const params = {
        ...this.storageValue,
        permissions: this.selectedList,
      };

      add(params).then(() => {
        this.$close(this.$route.path);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.info {
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}

.group-name {
  color: #1890ff;
  margin-bottom: 16px;
}
.permission-list {
  margin-bottom: 24px;
}
</style>
